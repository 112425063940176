// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    // test
    apiKey: 'AIzaSyAAC6VofbGhDbtBiqqcLnrrMLr_NeMGEHU',
    authDomain: 'primeclap-be98b.firebaseapp.com',
    projectId: 'primeclap-be98b',
    storageBucket: 'primeclap-be98b.appspot.com',
    messagingSenderId: '678935753557',
    appId: '1:678935753557:web:1d4090a31c2e83d57af5dc',
    measurementId: 'G-5T7CDX24NM',

    //production
    // apiKey: 'AIzaSyDSgN69u0ib1T5YQwNiD5AihMdMnmju4xY',
    // authDomain: 'primeclap-bbb0a.firebaseapp.com',
    // projectId: 'primeclap-bbb0a',
    // storageBucket: 'primeclap-bbb0a.appspot.com',
    // messagingSenderId: '635694781275',
    // appId: '1:635694781275:web:3691bf664a4691fe3b407e',
    // measurementId: 'G-3P9LR39VDT',
  },
  recaptcha: {
    siteKey: '6LejlgQgAAAAAPUQ15EYPUJhp5uYSZjI3oEmixF',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
